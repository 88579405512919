import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Offcanvas, Button } from "react-bootstrap";
import MenuIcon from "../assets/images/menu-icon.png";
import MyMenuIcon from "../assets/images/user-icon.svg";
import UserIcon from "../assets/images/icons/user.png";
import WalletIcon from "../assets/images/WalletIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import MobLeftbar from "./MobLeftbar";
import { AiOutlineSearch } from "react-icons/ai";
import numberWithCommas from "../utils/numbersWithComma";
import BottomHead from "./BottomHead";

import HomeIcon from "../assets/images/icons/home-icon.svg";
import HomeClrdIcon from "../assets/images/icons/home-clrd-icon.svg";
import WhatsappIcon from "../assets/images/icons/whatsapp-icon.png";
import MailIcon from "../assets/images/icons/mail-icon.png";
import FacebookIcon from "../assets/images/icons/facebook-icon.png";
import TelegramIcon from "../assets/images/icons/telegram-icon.png";
import ApkDwldIcon from "../assets/images/icons/icon-apk.png";
import HelpChatIcon from "../assets/images/icons/icon-help.png";
import ProfileIcon from "../assets/images/icons/user.png";
import BetIcon from "../assets/images/icons/bets.png";
import BonusImg from "../assets/images/icons/bonus.png";
import refferal from "../assets/images/icons/referral.png";
import AcStatement from "../assets/images/icons/statement.png";
import LogoutIconImg from "../assets/images/icons/logout.png";
import InplayBanner from "../assets/images/banner/banner-inplay.png";
import CricketBanner from "../assets/images/banner/banner-cricket.png";
import SoccerBanner from "../assets/images/banner/banner-soccer.png";
import TennisBanner from "../assets/images/banner/banner-tennis.png";
import HorseBanner from "../assets/images/banner/banner-horseracing.png";
import IndianFlag from "../assets/images/india.png";

const HeaderAfterLogin = () => {
  const { user, balance, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const isApk = localStorage.getItem("isApk") === "true";
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(!show1);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  // useEffect(() => {
  //   const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
  //   if (isClassAddedLocalStorage) {
  //     setIsClassAdded(true);
  //     document.body.classList.add("light-theme");
  //   }
  // }, []);
  // const toggleClass = () => {
  //   if (isClassAdded) {
  //     setIsClassAdded(false);
  //     localStorage.removeItem("isClassAdded");
  //     document.body.classList.remove("light-theme");
  //   } else {
  //     setIsClassAdded(true);
  //     localStorage.setItem("isClassAdded", true);
  //     document.body.classList.add("light-theme");
  //   }
  // };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (show1) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [show1]);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "/spmsports/cricket",
    "/betby",
    "/live-dealer",
    "/casino/evolution",
    "/casino/spribe/aviator",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  // console.log("balance", wallet);
  const [isBottomHead, setIsBottomHead] = useState(true);
  useEffect(() => {
    if (location.pathname.startsWith("/spmsports/")) {
      setIsBottomHead(false);
    } else {
      setIsBottomHead(true);
    }
  }, [isBottomHead]);

  // Date and Time Start ======>>>>>>
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
  // <<<<<<====== Date and Time End

  const settings = {
    arrows: true,
    dots: false,
    swipeToSlide: true,
    infinite: false,
    autoplay: false,

    slidesToShow: 4,
    slidesToScroll: 1,

    speed: 3000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <header className="header afterlogin">
        <div className="top_head d-none d-lg-block">
          <div className="custom-container">
            <div className="top-content">
              <ul className="time-lang">
                <li className="time">(GMT+5.5) {formattedTime}</li>
                <li className="lang">
                  <img src={IndianFlag} alt="indian flag" />
                  <p>
                    <span>৳ BDT</span> English
                  </p>
                </li>
              </ul>

              <ul className="social-contact">
                <li>
                  <a
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={WhatsappIcon} alt="whatsapp" />

                    <span>WhatsApp</span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={MailIcon} alt="email" />
                    <span>Email</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={FacebookIcon} alt="facebook" />
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={TelegramIcon} alt="telegram" />
                    <span>Telegram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="middle_head">
          <div className="custom-container">
            <div className="d-flex align-items-center">
              <Button
                variant="primary menu-btn"
                onClick={handleShow1}
                className="d-block d-lg-none"
              >
                <img src={MenuIcon} alt="menu" width={25} />
              </Button>

              <div className="logo">
                <a href="/sports">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </a>
              </div>

              <div className="headerRight ms-auto d-none d-lg-flex">
                <ul>
                  <li className="balance">
                    <div className="balance-amount" href="/cashier">
                      Main Wallet &nbsp; &nbsp; ৳ &nbsp;
                      {wallet?.balance}
                      {/* <span>Bonus: {numberWithCommas(wallet?.coins)}</span> */}
                    </div>
                  </li>
                  <li className="deposit">
                    <a href="/gatewaylist">Deposit</a>
                  </li>
                </ul>

                <Button variant="primary menu-btn" onClick={handleShow}>
                  <img src={MyMenuIcon} alt="my menu" width={25} />
                </Button>
              </div>

              <div className="apk-help ms-auto d-lg-none">
                {!isApk && (
                  <a href="" download="">
                    <img src={ApkDwldIcon} alt="download apk" />
                    <span>App</span>
                  </a>
                )}
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={HelpChatIcon} alt="help" />
                  <span>Help</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="Bottom_head d-none d-lg-block">
          <div className="custom-container">
            <ul>
              <li className="home-app home">
                <a href="/">
                  <i class="icon home"></i>
                </a>
              </li>
              <li className="home-app app">
                <a href="/AppDownload" target="_blank">
                  <i class="icon app"></i>
                </a>
              </li>
              <li className="sports_menu">
                <a href="javascript:void(0)">
                  Sports
                  <i class="icon-angle-down"></i>
                </a>
                <div className="sports-games">
                  <Slider {...settings}>
                    <div>
                      <h5>Inplay</h5>
                      <a href="/spmsports/cricket">
                        <img src={InplayBanner} alt="inplay" />
                      </a>
                    </div>
                    <div>
                      <h5>Cricket</h5>
                      <a href="/spmsports/cricket">
                        <img src={CricketBanner} alt="cricket" />
                      </a>
                    </div>
                    <div>
                      <h5>Soccer</h5>
                      <a href="/spmsports/Soccer">
                        <img src={SoccerBanner} alt="soccer" />
                      </a>
                    </div>
                    <div>
                      <h5>Tennis</h5>
                      <a href="/spmsports/Tennis">
                        <img src={TennisBanner} alt="tennis" />
                      </a>
                    </div>
                    <div>
                      <h5>Horse Racing</h5>
                      <a href="/spmsports/Horse Racing">
                        <img src={HorseBanner} alt="horse racing" />
                      </a>
                    </div>
                  </Slider>
                </div>
              </li>
              {/* <li>
                <a href="/spmsports/cricket">In-Play</a>
              </li>
              <li>
                <a href="/spmsports/cricket">Cricket</a>
              </li>
              <li>
                <a href="/spmsports/soccer">Footbal</a>
              </li>
              <li>
                <a href="/spmsports/tennis">Tennis</a>
              </li> */}
              <li>
                <a href="/casino">Casino</a>
              </li>
              <li>
                <a href="/Slots">Slots</a>
              </li>
              {/* <li>
                <a href="/spmsports/horse-racing">Horse Racing</a>
              </li> */}
              <li>
                <a href="/spmsports/cricket">Exchange</a>
              </li>
              <li>
                <a href="/betby">Sports Book</a>
              </li>
              <li>
                <a href="/casino/qtech">Qtech</a>
              </li>
            </ul>
          </div>
        </div>

        <Offcanvas
          className="user_section"
          show={show}
          onHide={handleClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={UserIcon} alt="User Icon" />
                </div>
                <div className="user-info">
                  <Link className="user_name">{user?.mstruserid}</Link>
                  <p>{user?.mobileno}</p>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    {" "}
                    BDT {wallet?.balance}
                  </div>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>
              </div>
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={ProfileIcon} alt="my bet" />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={BetIcon} alt="my bet" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={AcStatement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>

              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  {/* <LogoutIcon /> */}
                  <img src={LogoutIconImg} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>

            {/* <div className="theme-btn">
              <buton type="button" className="btn" onClick={toggleClass}>
                <img src={MoonLight} alt="moon" className="dark_theme" />
                <img src={SunLight} alt="sun" className="light_theme" />
              </buton>
            </div> */}
          </Offcanvas.Body>
        </Offcanvas>

        {show1 && <MobLeftbar className="d-block d-lg-none" />}
      </header>

      {/* {isBottomHead && <BottomHead />} */}
      {/* <BottomHead /> */}
    </>
  );
};

export default HeaderAfterLogin;
