import React, { useEffect, useState } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { Navigate, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bkash from "../../../../assets/images/icons/bkash.jpg";
import binance from "../../../../assets/images/icons/binance.jpg";
import upay from "../../../../assets/images/icons/upay.jpg";
import trc20 from "../../../../assets/images/icons/trc20.jpg";
import nagad from "../../../../assets/images/icons/nagad.jpg";
import rocket from "../../../../assets/images/icons/rocket.jpg";

import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container } from "react-bootstrap";

import Rocket from "../../../../assets/images/DepositIMG/Rocket.png";
import Bkash from "../../../../assets/images/DepositIMG/Bkash.png";
import Nogot from "../../../../assets/images/DepositIMG/Nogot.jpeg";
import Upay from "../../../../assets/images/DepositIMG/Upay.png";
import Tron from "../../../../assets/images/DepositIMG/Tron.png";
import Form from "react-bootstrap/Form";
import { FaCheck } from "react-icons/fa6";
const GatewaysList = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const { balance, wallet } = useSelector((state) => state.auth);

  const { gatewaysList } = useSelector((state) => state.user);
  const { selectedGateway } = useSelector((state) => state.user);

  const { bankDetails } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const isDemoUser = user?.mstrname === process.env.REACT_APP_DEMO_USER_ID;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);
  // const [gateway, setGateWay] = useState();

  const handleGatewayClick = (item) => {
    dispatch(setSelectedGateway(item));
    setFormData({});
    // setGateWay(item.name);
    // navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    navigate(`/deposit/bank_transfer`);
  };

  return (
    <div>
      {isDemoUser && <Navigate to="/" />}
      <HeaderAfterLogin />

      <main className="main " style={{ paddingInline: "5px" }}>
        <Container>
          <div className="pages_top_head">
            <h5>Deposit</h5>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="wallet_info">
            <div className="T_wallet">Total Wallet Balance</div>
            <div className="amt">BDT {wallet?.balance}</div>
            <div className="main_wallet">
              <div>Main Wallet: BDT {wallet?.cash}</div>
              <div className="history" onClick={() => navigate("/cashier")}>
                Deposit transaction history
              </div>
            </div>
          </div>
          <div>
            <div className="transaction_title">
              Deposit Type{" "}
              <span style={{ color: "blue", fontSize: "medium" }}>*</span>
            </div>
            <div>
              <Form.Select aria-label="Default select example">
                <option>Select Deposit Type</option>
                <option selected value="Online Transfer">
                  Online Transfer
                </option>
              </Form.Select>
            </div>
          </div>
          <div className="gatewaySection">
            <h5 className="payment_method">Payment Method</h5>
            <div className="gatewaypart d-flex">
              {gatewaysList?.deposits
                ?.sort((a, b) => a.order - b.order)
                .map((item, index) => {
                  return (
                    <div
                      className={
                        selectedGateway?.code === item?.code
                          ? "cards1 active"
                          : "cards1 "
                      }
                      onClick={() => {
                        handleGatewayClick(item);
                      }}
                      key={index}
                    >
                      <div className="card-icon">
                        {/* <img src={item?.logo || DepositImg} /> */}
                        {item?.code === "m_bkash" ? (
                          <img src={bkash} />
                        ) : item?.code === "m_nagad" ? (
                          <img src={nagad} />
                        ) : item?.code === "m_upay" ? (
                          <img src={upay} />
                        ) : item?.code === "m_rocket" ? (
                          <img src={rocket} />
                        ) : item?.code === "m_binance" ? (
                          <img src={binance} />
                        ) : item?.code === "m_ethereum_erc20" ? (
                          <img src={trc20} />
                        ) : (
                          <img src={DepositImg} />
                        )}
                      </div>{" "}
                      <p style={{ marginBottom: "2px" }}>{item?.name}</p>
                      {/* {selectedGateway?.code === item?.code && (
                        <div>
                          <FaCheck />
                        </div>
                      )} */}
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div className="gatewaySection">
            <div className="gatewaypart">
              <Tabs
                defaultActiveKey="Deposit"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="Deposit" title="Online Deposit">
                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "auto")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={DepositImg} />
                          </div>

                          <p style={{ color: "#333" }}>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>
                <Tab eventKey="bank" title="Bank Transfer">
                  {bankDetails && (
                    <div
                      className="cards1"
                      onClick={() => {
                        handleBankTransferClick();
                      }}
                    >
                      {" "}
                      <div className="card-icon">
                        <img src={bankImg} style={{ width: "66px" }} />
                      </div>
                      <p style={{ color: "#333" }}>{"Bank Transfer"}</p>
                    </div>
                  )}

                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "manual")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            {item.code === "m_rocket" ? (
                              <img src={Rocket} />
                            ) : item.code === "m_bkash" ? (
                              <img src={Bkash} />
                            ) : item.code === "m_nagad" ? (
                              <img src={Nogot} />
                            ) : item.code === "m_upay" ? (
                              <img src={Upay} />
                            ) : item.code === "m_binance_tron" ? (
                              <img src={Tron} />
                            ) : (
                              <img src={DepositImg} />
                            )}
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>
              </Tabs>
            </div>
          </div> */}
          <div>
            {selectedGateway?.name && (
              <DepositGateway formData={formData} setFormData={setFormData} />
            )}
          </div>
        </Container>
      </main>
    </div>
  );
};
export default GatewaysList;
