import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

import FbIconImg from "../assets/images/provider/facebook.png";
import InstaIconImg from "../assets/images/provider/instagram.png";
import logo from "../assets/images/logo.png";
import AppuGames from "../assets/images/provider/Betfair.jpg";
import EvolutionGames from "../assets/images/provider/provider-logo-evolution.jpg";
import EzugiGames from "../assets/images/provider/provider-logo-ezugi.jpg";

import binance from "../assets/images/payments/new/Binance.png";
import pmicon from "../assets/images/payments/new/Pm.png";
import therthnt from "../assets/images/payments/new/Tether.png";
import Bitcoin from "../assets/images/payments/new/Bitcoin.png";
import Phonepay from "../assets/images/payments/new/phonepe-logo-icon.png";
import Paytm from "../assets/images/payments/new/Paytm.png";
import GooglaPay from "../assets/images/payments/new/Gpay.png";
import UPIcon from "../assets/images/payments/new/Upi.png";

import Calling1 from "../assets/images/icons/phone.png";
import Whatapps from "../assets/images/icons/whatsapp.png";
import Telegram from "../assets/images/icons/telegram.png";
import Mail from "../assets/images/icons/mail.png";

import WhatsaPPImg from "../assets/images/icons/whatsappfoot.png";
import TelegramImg from "../assets/images/icons/telegramfoot.png";
import BottomNav from "./BottomNav";

import CustomerSupport from "../assets/images/icons/footer/hours.png";
import BrandAmbassador from "../assets/images/icons/footer/icon-ambassador.png";
import NewMemberGuide from "../assets/images/icons/footer/icon-book.png";
import APKImg from "../assets/images/icons/footer/android-en.png";

import gamecare from "../assets/images/icons/footer/safe1.png";
import plus18 from "../assets/images/icons/footer/safe2.png";
import safe3 from "../assets/images/icons/footer/safe3.png";

import pay1 from "../assets/images/icons/footer/pay16.png";
import pay2 from "../assets/images/icons/footer/pay18.png";
import pay3 from "../assets/images/icons/footer/pay20.png";
import pay4 from "../assets/images/icons/footer/pay47.png";
import pay5 from "../assets/images/icons/footer/pay91.png";

import FacebookImg from "../assets/images/icons/footer/FaceBook.png";
import InstagramImg from "../assets/images/icons/footer/Instagram.png";
import PinterestImg from "../assets/images/icons/footer/Pinterest.png";
import TwitterImg from "../assets/images/icons/footer/Twitter.png";
import YoutubeImg from "../assets/images/icons/footer/Youtube.png";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <div className="SocialMediaSection">
        <Container>
          <ul>
            <li>
              <a href={`tel://${appDetails?.PHONE}`}>
                <img src={Calling1} alt="Calling icon" />
                <span>+91 </span>
              </a>
            </li>
            <li>
              <a href="">
                <img src={Mail} alt="Calling icon" />
                <span></span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatapps} alt="Calling icon" />
                <span>Whatsapp</span>
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Calling icon" />
                <span>Telegram</span>
              </a>
            </li>
          </ul>
        </Container>
      </div>
      <footer className="footer">
        <Container fluid>
          <div className="SupportSection">
            <ul>
              <li>
                <img src={CustomerSupport} alt="CustomerSupport" />
                <div>
                  <p>Customer Support</p>
                  <p className="smallp">Available 24/7 to assist you</p>
                </div>
              </li>
              <li>
                <img src={NewMemberGuide} alt="NewMemberGuide" />
                <div>
                  <p>New Member Guide</p>
                  <p className="smallp">Check out FAQ and guides</p>
                  <a href="javascript:void(0)">Explore Now</a>
                </div>
              </li>
              <li>
                <img src={BrandAmbassador} alt="BrandAmbassador" />
                <div>
                  <p>Brand Ambassador</p>
                  <p className="smallp">Play with celebrity</p>
                  <a href="javascript:void(0)">Have Fun Now</a>
                </div>
              </li>
              <li>
                <a href="" download="">
                  <img src={APKImg} alt="BrandAmbassador" className="ApkImg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="SocialSection">
            <ul>
              <li>
                <h5>Responsible Gaming</h5>
                <div className="partnerSection">
                  <a href="javascript:void(0)">
                    <img src={gamecare} alt="gamecare" />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={plus18} alt="gamecare" />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={safe3} alt="gamecare" />
                  </a>
                </div>
              </li>

              <li>
                <h5>Payments Method</h5>
                <div className="partnerSection">
                  <a href="javascript:void(0)">
                    <img src={pay1} alt="gamecare" className="footerwrap" />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={pay2} alt="gamecare" className="footerwrap" />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={pay3} alt="gamecare" className="footerwrap" />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={pay4} alt="gamecare" className="footerwrap" />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={pay5} alt="gamecare" className="footerwrap" />
                  </a>
                </div>
              </li>
              <li>
                <h5>Community Websites</h5>
                <div className="partnerSection">
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={FacebookImg} />
                  </a>
                  <a href="javascript:void(0)">
                    <a
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "#"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={InstagramImg} />
                    </a>
                  </a>
                  <a href="javascript:void(0)">
                    <a
                      href={
                        appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={TelegramImg} />
                    </a>
                  </a>
                  <a href="javascript:void(0)">
                    <a
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsaPPImg} />
                    </a>
                  </a>
                  <a href="javascript:void(0)">
                    <img src={TwitterImg} />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={PinterestImg} />
                  </a>
                  <a href="javascript:void(0)">
                    <img src={YoutubeImg} />
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <div className="FooterContent">
            <h4>
              Top Betting Exchange Sites Bangladesh, India & South East Asia
            </h4>
            <p>
              A betting exchange is practically an online tool that is designed
              for gamblers to bet directly against each other and not involve
              any of the traditional bookmakers. Cricket Betting indicates two
              ways of betting in a cricket match. The first one is to bet on the
              overall outcome of a live cricket match. The other one is based on
              the live cricket score of a team in the first 6 overs.
            </p>
            <p>
              Online betting has developed as a booming industry in South East
              Asia especially in Bangladesh and India, where the bettors get to
              choose from an exciting range of Top Betting Exchange Sites in
              Bangladesh, India and others South East Asia Region.
            </p>
            <p>
              If you find this interesting and are in search of a reliable and
              safe Cricket Betting Sites Bangladesh and India, then you should
              enrol with us. Gamexch365 is a reputed online gambling site in the
              entire Asia. We focus on not only cricket predictions but also
              other exciting online gaming products.
            </p>
          </div>
        </Container>
        <Container>
          <div className="sectionDivide">
            <div className="singleSection">
              <div className="footer-logo">
                <a href="#">
                  <img src={appDetails?.LOGO_URL || logo} alt="Logo" />
                </a>
              </div>
              <div className="footer-links">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <ul>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                        <li>
                          <a href="/privacypolicy">Privacy Policy</a>
                        </li>

                        <li>
                          <a href="/ResponsibleGaming">Responsible Gaming</a>
                        </li>
                        <li>
                          <a href="/fairplay">Fair Play</a>
                        </li>
                        <li>
                          <a href="/gamerules">Games Rules</a>
                        </li>
                        <li>
                          <a href="/termsandcondition">Terms and Conditions</a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <Row>
            <Col xs={12}></Col>
          </Row>
        </Container>

        <div>
          <BottomNav />
        </div>
      </footer>
    </>
  );
};

export default Footer;
