import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";

import UserName from "../../../assets/images/icons/icon-user.png";
import PasswordIcon from "../../../assets/images/icons/icon-pass.png";

import PhoneInput from "react-phone-input-2";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);
  const [mobileNumber, setMobileNumber] = useState("");
  const [changeModalContent, setChangeModalContent] = useState(true);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const loginDemoUser = () => {
    const payload = {
      username: process.env.REACT_APP_DEMO_USER_ID,
      password: process.env.REACT_APP_DEMO_USER_PASSWORD,
    };
    dispatch(loginUser(payload));
  };
  const handleSubmitForgotPass = async (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            setIsOtpSent(true);
            // navigate("/reset-password");
            // handleClose();
            // handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  const handleSubmitResetPass = () => {
    setIsVerifying(true);

    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          // navigate("/sign-in");
          // handleClose();
          // handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
          setChangeModalContent((prev) => !prev);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
          setIsVerifying(false);
        });
    }
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal LoginModal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>Welcome to Gamexch365</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {changeModalContent ? (
          <div
            // className="login-section"
            className={
              !changeModalContent ? `login-section` : `login-section active`
            }
          >
            {/* <div className="login-leftPart d-none">
            <img src={LoginLeftPart} className="signin" />
          </div> */}
            <div className="Login-rightPart">
              {/* <Modal.Title>Log in now!</Modal.Title> */}
              <div className="form-steps">
                {/* <h2 className="h2">Welcome to</h2>
              <img
                className="login-modal-img"
                src={appDetails?.LOGO_URL}
                alt="Logo"
              /> */}

                <Form>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicName"
                  >
                    <label>Username</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Username*"
                      name="username"
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <img src={UserName} alt="UserName" className="InputImg1" />
                    <Form.Control.Feedback type="invalid">
                      {errors?.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <label>Password</label>

                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <img
                      src={PasswordIcon}
                      alt="PasswordIcon"
                      className="InputImg"
                    />
                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-end mb-3">
                    <a
                      className="form-link"
                      onClick={() => {
                        // handleClose();
                        // handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                        setChangeModalContent((prev) => !prev);
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Login now
                  </Button>
                </Form>

                {/* <div className="sign-up mt-4">
                <Button
                  variant="primary"
                  className=""
                  style={{ width: "200px" }}
                  onClick={loginDemoUser}
                >
                  Log In With Demo Id
                </Button>
              </div> */}
                <div className="sign-up signupsection mt-4">
                  Do not have an account? <a href="/register">Sign Up Now!</a>
                </div>

                <div className="sportsSection">
                  <img src={MumbaiSports} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              changeModalContent
                ? `login-section`
                : `login-section ForgotPasswordSection`
            }
            // {`login-section  ForgotPasswordSection `}
          >
            <div className="Login-rightPart">
              <div className="form-steps">
                <Form onSubmit={handleSubmitForgotPass}>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                    p
                  >
                    <label>Phone Num</label>
                    <PhoneInput
                      country={"bd"}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      onlyCountries={["in", "bd"]}
                      inputStyle={{
                        display: "block",
                        width: "100%",
                        lineHeight: 1.5,
                        color: "#ffffff",
                        backgroundColor: "#26324B",
                        backgroundClip: "padding-box",
                        border: "#26324B",
                        borderRadius: "10px",
                        padding: "0.7rem 0.75rem",
                        borderColor: "#26324B",
                        fontSize: "0.86rem",
                        minHeight: "41px",
                        paddingLeft: "50px",
                      }}
                      onChange={(phone) => {
                        // alert("phone", phone);
                        // alert(phone);
                        setMobileNumber(phone);
                      }}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmitForgotPass}
                  >
                    Get OTP
                  </Button>
                </Form>
                <Form className="mt-2">
                  <Form.Group className="mb-3 position-relative" controlId="">
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP*"
                      onChange={(e) => setOtpValue(e.target.value)}
                      autoComplete="off"
                    />
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                  </Form.Group>
                </Form>
                <Button
                  variant="primary"
                  onClick={handleSubmitResetPass}
                  disabled={!isOtpSent}
                >
                  {!isVerifying ? "SUBMIT" : "SUBMITTING"}
                </Button>
                <div
                  className="my-1 LoginBack"
                  onClick={() => setChangeModalContent((prev) => !prev)}
                >
                  Back To Login
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
