import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button, Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import ModalWrapper from "./ModalWrapper";
import HomeIcon from "../assets/images/icons/home-icon.svg";
import WhatsappIcon from "../assets/images/icons/whatsapp-icon.png";
import MailIcon from "../assets/images/icons/mail-icon.png";
import FacebookIcon from "../assets/images/icons/facebook-icon.png";
import TelegramIcon from "../assets/images/icons/telegram-icon.png";
import ApkDwldIcon from "../assets/images/icons/icon-apk.png";
import HelpChatIcon from "../assets/images/icons/icon-help.png";
import InplayBanner from "../assets/images/banner/banner-inplay.png";
import CricketBanner from "../assets/images/banner/banner-cricket.png";
import SoccerBanner from "../assets/images/banner/banner-soccer.png";
import TennisBanner from "../assets/images/banner/banner-tennis.png";
import HorseBanner from "../assets/images/banner/banner-horseracing.png";

import IndianFlag from "../assets/images/india.png";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const isApk = localStorage.getItem("isApk") === "true";
  const { appDetails } = useSelector((state) => state.app);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleShow1 = () => setShow1(!show1);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (show1) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [show1]);

  // Date and Time Start ======>>>>>>
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
  // <<<<<<====== Date and Time End

  const settings = {
    arrows: true,
    dots: false,
    swipeToSlide: true,
    infinite: false,
    autoplay: false,

    slidesToShow: 4,
    slidesToScroll: 1,

    speed: 3000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <header className="header header_bfrlgn">
        <div className="top_head d-none d-lg-block">
          <div className="custom-container">
            <div className="top-content">
              <ul className="time-lang">
                <li className="time">(GMT+5.5) {formattedTime}</li>
                <li className="lang">
                  <img src={IndianFlag} alt="indian flag" />
                  <p>
                    <span>৳ BDT</span> English
                  </p>
                </li>
              </ul>

              <ul className="social-contact">
                <li>
                  <a
                    className="wtp"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={WhatsappIcon} alt="whatsapp" />

                    <span>WhatsApp</span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={MailIcon} alt="email" />
                    <span>Email</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={FacebookIcon} alt="facebook" />
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    className="tg"
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={TelegramIcon} alt="telegram" />
                    <span>Telegram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="middle_head">
          <div className="custom-container">
            <div className="d-flex align-items-center">
              <Button
                variant="primary menu-btn"
                onClick={handleShow1}
                className="d-block d-lg-none"
              >
                <img src={MenuIcon} alt="User Menu Icon" width={25} />
              </Button>
              <div className="logo">
                <a href="/">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </a>
              </div>

              <div className="login-buttons ms-auto">
                <Button
                  variant="primary"
                  // onClick={() => {
                  //   navigate("/sign-in");
                  // }}
                  onClick={() => {
                    !isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  className="login_btn"
                >
                  Login
                </Button>
                <Button
                  variant="primary"
                  // onClick={() => {
                  //   navigate("/mobile-number");
                  // }}

                  onClick={() => {
                    // isMobile
                    //   ? handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
                    //   : navigate("/register");
                    navigate("/register");
                  }}
                  className="signup_btn"
                >
                  Sign up
                </Button>
              </div>

              <div className="apk-help ms-auto d-lg-none">
                {!isApk && (
                  <a href="" download="">
                    <img src={ApkDwldIcon} alt="download apk" />
                    <span>App</span>
                  </a>
                )}
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={HelpChatIcon} alt="help" />
                  <span>Help</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="Bottom_head d-none d-lg-block">
          <div className="custom-container">
            <ul>
              <li className="home">
                <a href="/">
                  <i class="icon-home"></i>
                </a>
              </li>
              <li className="sports_menu">
                <a href="javascript:void(0)">
                  Sports
                  <i class="icon-angle-down"></i>
                </a>
                <div className="sports-games">
                  <Slider {...settings}>
                    <div>
                      <h5>Inplay</h5>
                      <a
                        onClick={() => {
                          !isMobile
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : navigate("/sign-in");
                        }}
                        href="#"
                      >
                        <img src={InplayBanner} alt="inplay" />
                      </a>
                    </div>
                    <div>
                      <h5>Cricket</h5>
                      <a
                        onClick={() => {
                          !isMobile
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : navigate("/sign-in");
                        }}
                        href="#"
                      >
                        <img src={CricketBanner} alt="cricket" />
                      </a>
                    </div>
                    <div>
                      <h5>Soccer</h5>
                      <a
                        onClick={() => {
                          !isMobile
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : navigate("/sign-in");
                        }}
                        href="#"
                      >
                        <img src={SoccerBanner} alt="soccer" />
                      </a>
                    </div>
                    <div>
                      <h5>Tennis</h5>
                      <a
                        onClick={() => {
                          !isMobile
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : navigate("/sign-in");
                        }}
                        href="#"
                      >
                        <img src={TennisBanner} alt="tennis" />
                      </a>
                    </div>
                    <div>
                      <h5>Horse Racing</h5>
                      <a
                        onClick={() => {
                          !isMobile
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : navigate("/sign-in");
                        }}
                        href="#"
                      >
                        <img src={HorseBanner} alt="horse racing" />
                      </a>
                    </div>
                  </Slider>
                </div>
              </li>
              {/* <li>
                <a href="/spmsports/cricket">In-Play</a>
              </li>
              <li>
                <a href="/spmsports/cricket">Cricket</a>
              </li>
              <li>
                <a href="/spmsports/soccer">Footbal</a>
              </li>
              <li>
                <a href="/spmsports/tennis">Tennis</a>
              </li> */}
              <li>
                <a
                  // onClick={() => {
                  //   !isMobile
                  //     ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  //     : navigate("/sign-in");
                  // }}
                  href="/casino"
                >
                  Casino
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    !isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  href="#"
                >
                  Slots
                </a>
              </li>
              {/* <li>
                <a href="/spmsports/horse-racing">Horse Racing</a>
              </li> */}
              <li>
                <a
                  onClick={() => {
                    !isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  href="#"
                >
                  Exchange
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    !isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  href="#"
                >
                  Sports Book
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    !isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  href="#"
                >
                  Qtech
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <LoginModal
          show={showAuthModals.login}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
          handleModalShow={handleModalShow}
        />
        {showAuthModals.mobileNumber && (
          <MobileNumberModal
            show={showAuthModals.mobileNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.verifyNumber && (
          <MobileNumberVerifyModal
            show={showAuthModals.verifyNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.register && (
          <RegisterModal
            show={showAuthModals.register}
            handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
            handleModalShow={handleModalShow}
          />
        )}

        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
          }
          handleModalShow={handleModalShow}
        />
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
          }
          handleModalShow={handleModalShow}
        /> */}

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Log in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
        {show1 && (
          <MobLeftbar
            className="d-block d-md-none"
            setShowAuthModals={setShowAuthModals}
          />
        )}
      </header>
    </>
  );
};

export default Header;
